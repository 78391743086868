import { defineStore } from "pinia";

const toastDuration = 2000;

export const useToastStore = defineStore({
  id: "toast",
  state: () => ({
    title: "" as string,
    text: "" as string,
    icon: null as "check" | "x" | "info" | null,
  }),
  getters: {
    show(state) {
      return state.title !== "";
    },
  },
  actions: {
    showErrorToast(title: string, text: string) {
      this.title = title;
      this.text = text;
      this.icon = "x";
      setTimeout(() => useToastStore().$reset(), toastDuration);
    },
    showSuccessToast(title: string, text: string) {
      this.title = title;
      this.text = text;
      this.icon = "check";
      setTimeout(() => useToastStore().$reset(), toastDuration);
    },
    showInfoToast(title: string, text: string) {
      this.title = title;
      this.text = text;
      this.icon = "info";
      setTimeout(() => useToastStore().$reset(), toastDuration);
    },
  },
});
