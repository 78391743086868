<template>
  <NuxtLayout>
    <NuxtPage />
    <Toast />
  </NuxtLayout>
</template>

<script setup lang="ts">
import Toast from "./components/ToastComponent.vue";
import { createAxiosInstance } from "~/utils/axiosClient.util";

await createAxiosInstance();
</script>
