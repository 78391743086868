import { default as _91id_93qQTCpdTGi7Meta } from "/vercel/path0/pages/clinics/[id].vue?macro=true";
import { default as create_45prescriberLZlQ6s36RwMeta } from "/vercel/path0/pages/clinics/create-prescriber.vue?macro=true";
import { default as createbEaT4hr9qXMeta } from "/vercel/path0/pages/clinics/create.vue?macro=true";
import { default as indexU39FNgu19BMeta } from "/vercel/path0/pages/clinics/index.vue?macro=true";
import { default as indexs9KBIDgIMmMeta } from "/vercel/path0/pages/customers/index.vue?macro=true";
import { default as index59OgeahOk2Meta } from "/vercel/path0/pages/delivery-schedules/index.vue?macro=true";
import { default as indexsA13j9DfJ1Meta } from "/vercel/path0/pages/drivers/index.vue?macro=true";
import { default as epos_45exportYoIvbbcZ5DMeta } from "/vercel/path0/pages/epos-export.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as manual_45paymentpzDWLXSd2LMeta } from "/vercel/path0/pages/manual-payment.vue?macro=true";
import { default as messagingC2PjKs7cgmMeta } from "/vercel/path0/pages/messaging.vue?macro=true";
import { default as order_45mapYM19le5L03Meta } from "/vercel/path0/pages/order-map.vue?macro=true";
import { default as _91id_93tvDo5AKAx4Meta } from "/vercel/path0/pages/orders/[id].vue?macro=true";
import { default as indexan9ZrZAr6jMeta } from "/vercel/path0/pages/orders/index.vue?macro=true";
import { default as _91id_93QjyYASYvsnMeta } from "/vercel/path0/pages/pharmacies/[id].vue?macro=true";
import { default as createtj847MamF7Meta } from "/vercel/path0/pages/pharmacies/create.vue?macro=true";
import { default as indexDIWP1llYdnMeta } from "/vercel/path0/pages/pharmacies/index.vue?macro=true";
import { default as _91id_93SGHsoCzKOpMeta } from "/vercel/path0/pages/prescribers/[id].vue?macro=true";
import { default as productspeZ43GAgSkMeta } from "/vercel/path0/pages/products.vue?macro=true";
import { default as questionnaires4zW6X5gk8TMeta } from "/vercel/path0/pages/questionnaires.vue?macro=true";
import { default as reportingldlYHKxnK8Meta } from "/vercel/path0/pages/reporting.vue?macro=true";
import { default as settingsyZZu4Rd1aAMeta } from "/vercel/path0/pages/settings.vue?macro=true";
import { default as tagsoS3BggMdP5Meta } from "/vercel/path0/pages/tags.vue?macro=true";
export default [
  {
    name: "clinics-id",
    path: "/clinics/:id()",
    component: () => import("/vercel/path0/pages/clinics/[id].vue").then(m => m.default || m)
  },
  {
    name: "clinics-create-prescriber",
    path: "/clinics/create-prescriber",
    component: () => import("/vercel/path0/pages/clinics/create-prescriber.vue").then(m => m.default || m)
  },
  {
    name: "clinics-create",
    path: "/clinics/create",
    component: () => import("/vercel/path0/pages/clinics/create.vue").then(m => m.default || m)
  },
  {
    name: "clinics",
    path: "/clinics",
    component: () => import("/vercel/path0/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: "customers",
    path: "/customers",
    component: () => import("/vercel/path0/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: "delivery-schedules",
    path: "/delivery-schedules",
    component: () => import("/vercel/path0/pages/delivery-schedules/index.vue").then(m => m.default || m)
  },
  {
    name: "drivers",
    path: "/drivers",
    component: () => import("/vercel/path0/pages/drivers/index.vue").then(m => m.default || m)
  },
  {
    name: "epos-export",
    path: "/epos-export",
    component: () => import("/vercel/path0/pages/epos-export.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "manual-payment",
    path: "/manual-payment",
    component: () => import("/vercel/path0/pages/manual-payment.vue").then(m => m.default || m)
  },
  {
    name: "messaging",
    path: "/messaging",
    component: () => import("/vercel/path0/pages/messaging.vue").then(m => m.default || m)
  },
  {
    name: "order-map",
    path: "/order-map",
    component: () => import("/vercel/path0/pages/order-map.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/vercel/path0/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/vercel/path0/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "pharmacies-id",
    path: "/pharmacies/:id()",
    component: () => import("/vercel/path0/pages/pharmacies/[id].vue").then(m => m.default || m)
  },
  {
    name: "pharmacies-create",
    path: "/pharmacies/create",
    component: () => import("/vercel/path0/pages/pharmacies/create.vue").then(m => m.default || m)
  },
  {
    name: "pharmacies",
    path: "/pharmacies",
    component: () => import("/vercel/path0/pages/pharmacies/index.vue").then(m => m.default || m)
  },
  {
    name: "prescribers-id",
    path: "/prescribers/:id()",
    component: () => import("/vercel/path0/pages/prescribers/[id].vue").then(m => m.default || m)
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/vercel/path0/pages/products.vue").then(m => m.default || m)
  },
  {
    name: "questionnaires",
    path: "/questionnaires",
    component: () => import("/vercel/path0/pages/questionnaires.vue").then(m => m.default || m)
  },
  {
    name: "reporting",
    path: "/reporting",
    component: () => import("/vercel/path0/pages/reporting.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/vercel/path0/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "tags",
    path: "/tags",
    component: () => import("/vercel/path0/pages/tags.vue").then(m => m.default || m)
  }
]