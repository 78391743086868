import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/browser";
import type { Router } from "vue-router";
import { sentry } from "~/config";

export default defineNuxtPlugin((nuxtApp) => {
  const { public: env } = useRuntimeConfig();
  const { vueApp } = nuxtApp;
  const gitCommitSHA = process.env.VERCEL_GIT_COMMIT_SHA;

  if (!env.is_loc)
    Sentry.init({
      app: [vueApp],
      dsn: sentry.dsn,
      environment: env.is_prd ? "prd" : "dev",
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(
            nuxtApp.$router as Router,
          ),
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      release: gitCommitSHA,
      beforeSend(event, hint) {
        // Check if it is an exception, and if so, log it.
        if (event.exception) {
          // eslint-disable-next-line no-console
          console.error(
            `[Exeption handled by Sentry]: (${hint.originalException})`,
            { event, hint },
          );
        }
        // Continue sending to Sentry
        return event;
      },
    });
  vueApp.mixin(
    Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ["activate", "mount", "update"],
    }),
  );
  Sentry.attachErrorHandler(vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ["activate", "mount", "update"],
  });

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
    },
  };
});
